// import { HTTP } from "config";
import { APICompanyListResponse } from "./response";

const apiCall = new Promise<APICompanyListResponse>((resolve) => {
  const apiResponse = {
    companies: [
      {
        id: 1,
        name: "Microsoft",
        slug: "microsoft",
      },
      {
        id: 2,
        name: "Amazon",
        slug: "amazon",
      },
      {
        id: 1,
        name: "Comcast",
        slug: "comcast",
      },
    ],
  } as APICompanyListResponse;
  setTimeout(function () {
    resolve(apiResponse);
  }, 250);
});

export async function APICompanyList(): Promise<APICompanyListResponse> {
  let apiResponse = {} as APICompanyListResponse;

  await apiCall.then((res) => {
    apiResponse = res;
  });

  // await HTTP.post("company/list")
  //   .then((res: any) => {
  //     apiResponse = res.data;
  //   })
  //   .catch(() => {});

  return apiResponse;
}
