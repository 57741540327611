import { IPageWrapper } from "./types";

const PageWrapper: React.FC<IPageWrapper> = ({ children }: IPageWrapper) => {
  return (
    <>
      <div className="bg-white">
        <div className="mx-auto py-12 pl-4 max-w-7xl">
          {children}
        </div>
      </div>
    </>
  );
};

export { PageWrapper };
