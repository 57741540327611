import { IBadge } from "./types";

const Badge: React.FC<IBadge> = ({ text }: IBadge) => {
  return (
    <>
      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
        <svg
          className="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
        {text}
      </span>
    </>
  );
};

export { Badge };
