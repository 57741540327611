// Local - Styled Components
import { SCYouTube } from "./styles";
// Local - TypeScript Types
import { IYouTube } from "./types";

const YouTube: React.FC<IYouTube> = ({ embedId }: IYouTube) => {
  return (
    <>
      <SCYouTube className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </SCYouTube>
    </>
  );
};

export { YouTube };
