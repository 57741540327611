import imgDiversity1 from "./assets/img/diversity-1.png";
import imgDiversity2 from "./assets/img/diversity-2.png";
import imgDiversity3 from "./assets/img/diversity-3.png";

const communities = [
  {
    name: "BLM",
    community: "Slack",
  },
  {
    name: "NAACP",
    community: "Slack",
  },
  {
    name: "Atlas Corps",
    community: "Facebook",
  },
];

const PageCommunityHome = () => {
  return (
    <>
      <section>
        <div className=" bg-gradient-to-r from-indigo-700 to-yellow-500 text-white py-20">
          <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-24">
            <div className="flex flex-col w-full lg:w-1/3 justify-center items-start p-8">
              <h1 className="text-3xl md:text-5xl p-2 text-yellow-300 tracking-loose">
                Community
              </h1>
              <h2 className="text-3xl md:text-5xl leading-relaxed md:leading-snug mb-2">
                The Timeless Connections
              </h2>
              <p className="text-sm md:text-base text-gray-50 mb-4">
                Explore your favourite events and register now to showcase your
                talent and win exciting prizes.
              </p>
              <a
                href="/"
                className="bg-transparent hover:bg-yellow-300 text-yellow-300 hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-yellow-300 hover:border-transparent"
              >
                Explore Now
              </a>
            </div>
            <div className="p-8 mt-12 mb-6 md:mb-0 md:mt-0 ml-0 md:ml-12 lg:w-2/3  justify-center">
              <div className="h-48 flex flex-wrap content-center">
                <div>
                  <img
                    alt="Diversity 1"
                    className="inline-block mt-28 hidden xl:block"
                    src={imgDiversity1}
                  />
                </div>
                <div>
                  <img
                    alt="Diversity 2"
                    className="inline-block mt-24 md:mt-0 p-8 md:p-0"
                    src={imgDiversity2}
                  />
                </div>
                <div>
                  <img
                    alt="Diversity 3"
                    className="inline-block mt-28 hidden lg:block"
                    src={imgDiversity3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex gap-5 flex-wrap">
          {communities.map((community, communityIndex) => {
            return (
              <div
                key={communityIndex}
                className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20"
              >
                <div className="flex justify-center md:justify-end -mt-16">
                  <img
                    alt="Diversity"
                    className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500"
                    src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                  />
                </div>
                <div>
                  <h2 className="text-gray-800 text-3xl font-semibold">
                    {community.name}
                  </h2>
                  <p className="mt-2 text-gray-600">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quae dolores deserunt ea doloremque natus error, rerum quas
                    odio quaerat nam ex commodi hic, suscipit in a veritatis
                    pariatur minus consequuntur!
                  </p>
                </div>
                <div className="flex justify-end mt-4">
                  <a href="/" className="text-xl font-medium text-indigo-500">
                    Find us on {community.community}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default PageCommunityHome;
