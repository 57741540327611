import "assets/css/tailwind.css";
import "./assets/css/WeStrideMain.css";
import NavTop from "./components/nav-top";
import { Outlet } from "react-router";

const LayoutMain = () => {
  return (
    <>
      <div>
        <NavTop />
        <div id="ws-content-wrapper">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default LayoutMain;
