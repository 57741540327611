import { Outlet } from "react-router";

const LayoutAuth: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default LayoutAuth;
