import { useEffect } from "react";
import { useLocation } from "react-router";
import { RoutesApp } from "routes";

const LayoutBase: React.FC = () => {
  const location = useLocation();

  // On every page change, scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <RoutesApp />
    </>
  );
};

export default LayoutBase;
