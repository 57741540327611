import {
  AcademicCapIcon,
  BadgeCheckIcon,
  BriefcaseIcon,
  CalendarIcon,
  UsersIcon
} from '@heroicons/react/outline'

export const youStride = [
  {
    name: 'Education',
    description: 'Discover new ways to empower yourself to grow even more.',
    href: '/education',
    icon: AcademicCapIcon
  },
  {
    name: 'Jobs',
    description:
      'Connect with companies that share your values and find a new job.',
    href: '/jobs',
    icon: BadgeCheckIcon
  },
  {
    name: 'Entrepreneurship',
    description: 'Start your own business, find funding and partners.',
    href: '/entrepreneurship',
    icon: BriefcaseIcon
  }
]
export const weStride = [
  {
    name: 'Events',
    description:
      'Find diverse events online or near you to help you in your journey.',
    href: '/events',
    icon: CalendarIcon
  },
  {
    name: 'Community',
    description:
      'Meet with like minded individuals and see new points of view.',
    href: '/community',
    icon: UsersIcon
  }
]
export const solutions = [
  {
    name: 'Events',
    description:
      'Find diverse events online or near you to help you in your journey.',
    href: '/events',
    icon: CalendarIcon
  },
  {
    name: 'Education',
    description: 'Discover new ways to empower yourself to grow even more.',
    href: '/education',
    icon: AcademicCapIcon
  },
  {
    name: 'Community',
    description:
      'Meet with like minded individuals and see new points of view.',
    href: '/community',
    icon: UsersIcon
  },
  {
    name: 'Jobs',
    description:
      'Connect with companies that share your values and find a new job.',
    href: '/jobs',
    icon: BadgeCheckIcon
  }
]
