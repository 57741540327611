// React
import { useEffect, useState } from "react";
// Routing
import { Link } from "react-router-dom";
// API's
import { APICompanyList } from "api/company/list";
import { APICompanyListResponse_CompanyDetails } from "api/company/list/response";
import { PageWrapper } from "components";

const PagePartners: React.FC = () => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [companies, setCompanies] = useState<
    Array<APICompanyListResponse_CompanyDetails>
  >([] as Array<APICompanyListResponse_CompanyDetails>);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      APICompanyList().then((apiResponse) => {
        setCompanies(apiResponse.companies);
      });
    }
  }, [firstLoad]);

  return (
    <>
      <PageWrapper>
        {companies.map((company) => {
          return (
            <div
              key={company.id}
              className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6"
            >
              <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {company.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit quam
                    corrupti consectetur.
                  </p>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0">
                  <Link to={`/company/${company.slug}`}>
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      View Company Profile
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </PageWrapper>
    </>
  );
};

export default PagePartners;
