// Routing
import { Route, Routes } from "react-router";
// Layouts
import LayoutMain from "layouts/main";
import LayoutAuth from "layouts/auth";
// Pages
import PageAbout from "pages/about";
import PageAuthLogin from "pages/auth/login";
import PageAuthRegister from "pages/auth/register";
import PageCommunityHome from "pages/community/home";
import PageCompany from "pages/company";
import PageEducationHome from "pages/education/home";
import PageEntrepreneurshipHome from "pages/entrepreneurship/home";
import PageEventsHome from "pages/events/home";
import PageHome from "./pages/home";
import PageJobsHome from "pages/jobs/home";
import PagePartners from "pages/partners";
import PageTalk from "pages/talk";

export const RoutesApp = () => (
  <Routes>
    <Route element={<LayoutMain />} path="/*">
      <Route element={<PageHome />} path="" />
      <Route element={<PageAbout />} path="about" />
      <Route element={<PageCommunityHome />} path="community" />
      <Route element={<PageCompany />} path="company/:slug" />
      <Route element={<PageEducationHome />} path="education" />
      <Route element={<PageEntrepreneurshipHome />} path="entrepreneurship" />
      <Route element={<PageEventsHome />} path="events" />
      <Route element={<PageJobsHome />} path="jobs" />
      <Route element={<PagePartners />} path="partners" />
      <Route element={<PageTalk />} path="talk" />
      <Route element={<LayoutAuth />} path="auth">
        <Route element={<PageAuthLogin />} path="login" />
        <Route element={<PageAuthRegister />} path="register" />
      </Route>
    </Route>
  </Routes>
);
